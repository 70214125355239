import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppContext } from 'components/AppContext'
import Logo from 'images/logo-new-01.svg'
import SMKBisa from 'images/logo-smk-bisa-hitam.svg'
import tutwuriLogo from 'images/tutwuri.png'
import vokasi from 'images/vokasi.png'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import useSWR from 'swr'
import formatNumber from 'util/formatNumber'
import getMediaURL from 'util/getMedia'
import socialMedia from 'vars/social-media'
import NewsletterForm from './NewsletterForm'

interface Props {}

const Footer = (props: Props) => {
  const { data } = useAppContext()
  const { pathname } = useRouter()
  const { data: visitor, revalidate } = useSWR<any>('/visitor', {
    revalidateOnMount: false,
    revalidateOnFocus: true,
  })

  useEffect(() => {
    revalidate()
  }, [pathname])

  return (
    <div className="footer border-t-2 border-gray-200 bg-white">
      <div className="container m-auto px-4 md:px-0 py-10">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-7">
            <div className="flex-1 flex items-center mb-6">
              <img
                src={Logo}
                className="hidden md:inline-block"
                style={{height: '3.6rem'}}
                alt="Direktorat Sekolah Menengah Kejuruan Direktorat Jenderal Pendidikan Vokasi Kementerian Pendidikan Kebudayaan Riset dan Teknologi"
              />
              <img
                src={tutwuriLogo}
                className="md:hidden"
                alt="Direktorat Sekolah Menengah Kejuruan Direktorat Jenderal Pendidikan Vokasi Kementerian Pendidikan Kebudayaan Riset dan Teknologi"
              />
              <img
                src={SMKBisa}
                className="ml-3"
                alt="SMK Bisa"
                style={{ maxHeight: 64 }}
              />
              {typeof data.logoEvent !== 'undefined' &&
                data.logoEvent.image && (
                  <img
                    src={getMediaURL('images', 'assets', data.logoEvent.image)}
                    className="ml-4"
                    style={{ maxHeight: 64 }}
                    title={data.logoEvent.name}
                    alt={data.logoEvent.name}
                  />
                )}
              <img src={vokasi} className="hidden ml-4" alt="SMK Bisa" />
            </div>
            <div className="md:w-8/12 text-sm">
              <div className="flex">
                <div className="mr-3 pt-1">
                  <FontAwesomeIcon icon={faMapMarkerAlt} width={14} />
                </div>
                <address className="flex-1 not-italic">
                  Komplek Kementerian Pendidikan dan Kebudayaan, Gedung E,
                  <br />
                  Lantai 12-13 Jl. Jend Sudirman, Senayan, Jakarta, 10270.
                </address>
              </div>
              <div className="flex flex-col md:flex-row mt-6 gap-4 md:gap-6">
                <div className="flex">
                  <a
                    href="mailto:program.psmk@kemdikbud.go.id"
                    className="flex hover:text-blue-200"
                  >
                    <span className="mr-3 pt-1">
                      <FontAwesomeIcon icon={faEnvelope} width={14} />
                    </span>
                    <span>program.psmk@kemdikbud.go.id</span>
                  </a>
                </div>
                <div className="flex">
                  <a href="tel:021-5725477" className="flex">
                    <span className="mr-3 pt-1">
                      <FontAwesomeIcon icon={faPhoneAlt} width={14} />
                    </span>
                    <span>021-5725477</span>
                  </a>
                </div>
              </div>
              <div className="col-span-12 mt-8 md:hidden md:col-span-5 md:mt-0">
                <NewsletterForm />
              </div>
              <div className="mt-10 grid grid-cols-2 md:grid-cols-3 gap-4 visitor">
                <div className="col-span-1">
                  <p className="font-bold text-xl">
                    {visitor ? formatNumber(visitor.today) : '...'}{' '}
                    {/* 7.192 */}
                  </p>
                  <p>Pengunjung hari ini</p>
                </div>
                <div className="col-span-1">
                  <p className="font-bold text-xl">
                    {visitor ? formatNumber(visitor.week) : '...'} {/* 7.192 */}
                  </p>
                  <p>Pengunjung minggu ini</p>
                </div>
                <div className="col-span-1">
                  <p className="font-bold text-xl">
                    {visitor ? formatNumber(visitor.month) : '...'}{' '}
                    {/* 7.192 */}
                  </p>
                  <p>Pengunjung bulan ini</p>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="col-span-12 mt-8 md:col-span-5 md:mt-0 hidden md:block">
            <NewsletterForm />
          </div>
        </div>
      </div>
      <div className="bg-blue-300 text-white px-4 md:px-0">
        <div className="container m-auto py-6 flex flex-col md:flex-row">
          <div className="flex-1 order-2 md:order-first">
            <p className="text-sm">
              &copy; {new Date().getFullYear()} Direktorat Sekolah Menengah
              Kejuruan. Kementerian Pendidikan dan Kebudayaan Republik
              Indonesia.
            </p>
          </div>
          <div className="order-1 pb-4 md:pb-0 md:order-last">
            <nav>
              <ul className="flex">
                <li className="mr-6 md:ml-6">
                  <a
                    href={socialMedia.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} className="w-5 h-5" />
                  </a>
                </li>
                <li className="mr-6 md:ml-6">
                  <a
                    href={socialMedia.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} className="w-5 h-5" />
                  </a>
                </li>
                <li className="mr-6 md:ml-6">
                  <a
                    href={socialMedia.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} className="w-5 h-5" />
                  </a>
                </li>
                <li className="mr-6 md:ml-6">
                  <a
                    href={socialMedia.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} className="w-5 h-5" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <style jsx>{`
        .footer {
          background-image: url(${require('images/Pattern.png')});
        }
      `}</style>
    </div>
  )
}

export default Footer

import React from 'react'
import useSWR from 'swr'
import Footer from './Footer'
import Header from './Header'
import Quotes from './Quotes'

interface Props {
  children: React.ReactNode
  menus: any[]
}

const BaseFrontend = ({ children, menus = [] }: Props) => {
  const { data } = useSWR('/menus', {
    revalidateOnMount: true,
    initialData: { menus },
  })

  return (
    <div className="flex flex-col min-h-screen">
      <Header menus={data.menus} />
      <div className="flex-1">{children}</div>

      <Quotes />
      <Footer />
    </div>
  )
}

export default BaseFrontend

const sanitizeUrl = (rawURL) => {
  if (/^(http|https):\/\/psmk.kemdikbud.go.id/.test(rawURL)) {
    const [, path] = rawURL.match(
      /^(?:http|https):\/\/psmk.kemdikbud.go.id\/(.*)/
    )
    return '/' + path
  }
  return rawURL
}

export default sanitizeUrl

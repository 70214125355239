const { colors, fontFamily, screens } = require('tailwindcss/defaultTheme')

module.exports = {
  purge: ['./components/**/*.{js,ts,jsx,tsx}', './pages/**/*.{js,ts,jsx,tsx}'],
  theme: {
    screens: { ...screens },
    extend: {
      container: {
        screens: {
          sm: '100%',
          md: '100%',
          lg: '1024px',
          xl: '1140px',
        },
      },
      fontFamily: {
        ...fontFamily,
        sans: "'Fira Sans', sans-serif",
      },
      colors: {
        ...colors,
        indigo: {
          ...colors.indigo,
          100: '#3F51B5',
        },
        orange: {
          ...colors.orange,
          100: '#F57C00',
        },
        blue: {
          ...colors.blue,
          50: '#0D47A1',
          100: '#0D47A1',
          200: '#002F79',
          300: '#092147',
        },
        grey: {
          100: '#F3F4F6',
        },
        turqoise: {
          100: '#00BCD4',
        },
      },
    },
  },
  variants: {},
  plugins: [require('@tailwindcss/custom-forms')],
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
}

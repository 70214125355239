import {
  faBars,
  faChevronDown,
  faQuestionCircle,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { useAppContext } from 'components/AppContext'
import { Form, Formik } from 'formik'
import { Input, Submit } from 'formstrap'
import Logo from 'images/logo-new-01.svg'
import SMKBisa from 'images/logo-smk-bisa-hitam.svg'
import tutwuriLogo from 'images/tutwuri.png'
import vokasi from 'images/vokasi.png'
import Link from 'next/link'
import { useRouter } from 'next/router'
import qs from 'querystring'
import React, { Fragment, useEffect, useState } from 'react'
import { theme } from 'tailwind.config'
import { getQueryParams } from 'util/fetcher'
import getMediaURL from 'util/getMedia'
import sanitizeUrl from 'util/sanitizeUrl'
interface Props {
  menus: any
}

const Header = ({ menus }: Props) => {
  const { data } = useAppContext()
  const [searchOpen, setSearchOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const router = useRouter()
  const onSearch = async (values) => {
    setIsMenuOpen(false)

    router.push('/search?' + qs.stringify(values))
  }

  const qparams = getQueryParams()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const body = window.document.getElementsByTagName('body')[0]
      if (isMenuOpen) {
        body.classList.add('overflow-hidden')
      } else {
        body.classList.remove('overflow-hidden')
      }
    }
    return () => {}
  }, [isMenuOpen])

  return (
    <Fragment>
      <div className="md:hidden" style={{ height: 56 }}></div>
      <div className="header fixed md:static md:h-auto w-full z-20 flex flex-col bg-white border-b border-gray-300 md:border-b-0">
        <div className="container m-auto">
          <div className="justify-end mb-3 hidden md:flex">
            <div className="bg-red-800 text-sm py-1 px-2 text-white rounded-bl-md rounded-br-md flex items-center">
              <span className="mr-2">
                Zona Integritas - Wilayah Bebas dari Korupsi
              </span>
              <FontAwesomeIcon icon={faQuestionCircle} width={13} />
            </div>
          </div>
          <div className="header-inner flex my-2 mx-4 md:mx-0 md:my-4">
            <div className="flex-1 flex items-center">
              <Link href="/">
                <a>
                  <img
                    src={Logo}
                    className="hidden md:block h-16"
                    alt="Direktorat Sekolah Menengah Kejuruan Direktorat Jenderal Pendidikan Vokasi Kementerian Pendidikan Kebudayaan Riset dan Teknologi"
                  />
                  <img
                    src={tutwuriLogo}
                    className="block md:hidden"
                    alt="Direktorat Sekolah Menengah Kejuruan Direktorat Jenderal Pendidikan Vokasi Kementerian Pendidikan Kebudayaan Riset dan Teknologi"
                  />
                </a>
              </Link>
              <img
                src={SMKBisa}
                className="hidden md:inline-block ml-10"
                style={{ maxHeight: 64 }}
                alt="SMK Bisa"
              />
              {typeof data.logoEvent !== 'undefined' &&
                data.logoEvent.image && (
                  <img
                    src={getMediaURL('images', 'assets', data.logoEvent.image)}
                    className="hidden md:inline-block ml-4"
                    style={{ maxHeight: 64 }}
                    title={data.logoEvent.name}
                    alt={data.logoEvent.name}
                  />
                )}
              <img
                src={vokasi}
                className="hidden md:inline-block-x ml-4"
                alt="SMK Bisa"
              />
            </div>
            <div className="flex items-center">
              <Formik initialValues={{ q: qparams.q }} onSubmit={onSearch}>
                <Form className="md:mr-4">
                  {searchOpen && (
                    <div className="relative">
                      <Input
                        name="q"
                        placeholder="Apa yang anda cari?"
                        type="text"
                        className="pr-12"
                        autoFocus
                        onBlurCapture={() => setSearchOpen(false)}
                      />
                      <Submit
                        color=""
                        className="bg-transparent hover:bg-transparent absolute right-0 top-0"
                      >
                        <FontAwesomeIcon icon={faSearch} width={20} />
                      </Submit>
                    </div>
                  )}
                  {!searchOpen && (
                    <button
                      className="appearance-none p-2 md:py-2 md:px-4"
                      onClick={() => setSearchOpen(true)}
                    >
                      <FontAwesomeIcon icon={faSearch} width={20} />
                    </button>
                  )}
                </Form>
              </Formik>
              <Link href="/auth/login">
                <a
                  className="hidden md:block border border-blue-100 rounded text-blue-100 px-4 py-2 font-semibold hover:bg-blue-100 hover:text-white transition-all ease-in-out duration-150"
                  onClick={() => {
                    setIsMenuOpen(false)
                  }}
                >
                  Masuk
                </a>
              </Link>
            </div>
            <div className="md:hidden">
              <button className="appearance-none p-2">
                <FontAwesomeIcon
                  icon={faBars}
                  width={20}
                  onClick={() => setIsMenuOpen(true)}
                />
              </button>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            'fixed top-0 bottom-0 w-screen transition-all ease-in-out duration-300 overflow-y-scroll md:overflow-y-auto md:w-auto md:bottom-auto md:top-auto md:static md:block border-t-2 border-gray-200 bg-white',
            { 'menu-open': isMenuOpen === true },
            { 'menu-close': isMenuOpen === false }
          )}
        >
          <div className="md:hidden border-b border-gray-300 px-4 py-2 flex items-center">
            <div className="flex-1">
              <img
                src={tutwuriLogo}
                className="block md:hidden"
                alt="Direktorat Sekolah Menengah Kejuruan Direktorat Jenderal Pendidikan Vokasi Kementerian Pendidikan Kebudayaan Riset dan Teknologi"
              />
            </div>
            <div>
              <button
                className="appearance-none px-2 text-2xl"
                onClick={() => setIsMenuOpen(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <div className="md:hidden mobile-search p-4 border-b border-gray-300">
            <Formik initialValues={{ q: '' }} onSubmit={onSearch}>
              <Form>
                <div className="relative">
                  <Input
                    name="q"
                    placeholder="Apa yang anda cari?"
                    type="text"
                    className="pr-12 w-full"
                    autoFocus
                    onBlurCapture={() => setSearchOpen(false)}
                  />
                  <Submit
                    color=""
                    className="bg-transparent hover:bg-transparent absolute right-0 top-0"
                  >
                    <FontAwesomeIcon icon={faSearch} width={20} />
                  </Submit>
                </div>
              </Form>
            </Formik>
          </div>
          <nav className="container m-auto">
            <ul className="text-sm">
              {menus.map((item, index) => (
                <li
                  className="border-b border-gray-300 md:border-b-0 block md:inline-block root-menu hover:text-blue-100"
                  key={index}
                >
                  <Link href={sanitizeUrl(item.link)}>
                    <a
                      href={sanitizeUrl(item.link)}
                      className="font-semibold flex items-center py-4 px-3"
                      onClick={(e) => {
                        if (item.link === '#') {
                          // e.preventDefault()
                        }
                        if (!(item.menu.length > 0)) {
                          setIsMenuOpen(false)
                        }
                      }}
                    >
                      {item.text}
                      {item.menu.length > 0 && (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="ml-3 text-gray-800"
                          width={13}
                        />
                      )}
                    </a>
                  </Link>
                  {item.menu.length > 0 && (
                    <ul className="sub-menu md:absolute bg-white md:rounded md:shadow-lg mt-1 overflow-hidden z-20 ml-4 md:ml-0">
                      {item.menu.map((item, index) => (
                        <li key={index}>
                          {item.link === null && item.text === null ? (
                            <div className="hidden md:block border-t-2 border-gray-300 mx-4"></div>
                          ) : (
                            <Link href={sanitizeUrl(item.link)}>
                              <a
                                href={sanitizeUrl(item.link)}
                                className="border-t border-gray-300 md:border-t-0 text-black py-2 px-3 font-semibold block md:hover:bg-blue-100 md:hover:text-white"
                                onClick={(e) => {
                                  if (item.link === '#') {
                                    e.preventDefault()
                                  }
                                  setIsMenuOpen(false)
                                }}
                              >
                                {item.text}
                              </a>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="md:hidden my-8 mx-4">
            <Link href="/auth/login">
              <a className="block text-center border border-blue-100 rounded text-blue-100 px-4 py-2 font-semibold hover:bg-blue-100 hover:text-white transition-all ease-in-out duration-150">
                Masuk
              </a>
            </Link>
          </div>
        </div>
        <style jsx>{`
          .header-inner {
            // min-height: 100px;
          }
          .root-menu {
          }
          .sub-menu {
            display: block;
            transition: all 0.3s;
            opacity: 0;
            transform-origin: top left;
            transform: scale(0);
          }
          .root-menu:hover .sub-menu {
            display: block;
            transform: scale(1);
            opacity: 1;
          }

          @media (min-width: ${theme.screens.md}) {
            .header {
              background-image: url(${require('images/Pattern.png')});
            }
          }
          @media (max-width: ${theme.screens.md}) {
            .menu-open {
              left: 0;
            }
            .menu-close {
              left: -100vw;
            }
            .root-menu .sub-menu {
              display: none;
            }
          }
        `}</style>
      </div>
    </Fragment>
  )
}

export default Header

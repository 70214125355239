import { QuotesItem } from 'entity'
import React, { Fragment } from 'react'
import useSWR from 'swr'
import getMediaURL from 'util/getMedia'
import Skeleton from './Skeleton'

interface Props {}

const Quotes = (props: Props) => {
  const { data } = useSWR('/quotes', {
    revalidateOnMount: true,
    initialData: { quotes: {} as QuotesItem },
  })

  return (
    <section id="quotes" className="container m-auto mb-20 px-4 md:px-0">
      <div className="grid grid-cols-3 bg-blue-300 text-white overflow-hidden rounded-lg shadow-md">
        <div className="h-64 md:h-auto col-span-3 md:col-span-1">
          <div
            className="bg-gray-400 h-full bg-no-repeat bg-cover bg-center"
            style={{
              backgroundImage: `linear-gradient(0deg, #092147, #092147), linear-gradient(0deg, #FFFFFF, #FFFFFF), url(${getMediaURL(
                'images',
                'quotes',
                data.quotes.image
              )})`,
              backgroundBlendMode: 'screen, color, normal',
            }}
          />
        </div>
        <div className="col-span-3 md:col-span-2 p-4 md:p-12">
          {!data.quotes.name ? (
            <div className="md:w-9/12">
              <Skeleton className="h-4 w-32 rounded mb-4 md:mb-6" />
              <div style={{ minHeight: '8rem' }}>
                <Skeleton className="h-6 w-9/12 rounded mb-3" />
                <Skeleton className="h-6 w-7/12 rounded mb-3" />
              </div>
              <Skeleton className="h-4 w-4/12 rounded mb-3" />
              <Skeleton className="h-4 w-6/12 rounded" />
            </div>
          ) : (
            <Fragment>
              <h3 className="font-semibold mb-2 md:mb-4">#PetuahPetuah</h3>
              <div style={{ minHeight: '8rem' }}>
                <h4 className="text-xl md:text-2xl md:w-9/12 font-semibold mb-4">
                  “{data.quotes.content}”
                </h4>
              </div>
              <p className="font-semibold mb-1">{data.quotes.name}</p>
              <p className="opacity-75">{data.quotes.occupation}</p>
            </Fragment>
          )}
        </div>
      </div>
    </section>
  )
}

export default Quotes

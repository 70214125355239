import clsx from 'clsx'
import React from 'react'

interface Props extends React.HTMLProps<HTMLDivElement> {}

const Skeleton = ({ className, ...props }: Props) => {
  return (
    <div {...props} className={clsx('animate-pulse bg-gray-300', className)} />
  )
}

export default Skeleton

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Formik } from 'formik'
import { Input, Submit } from 'formstrap'
import React, { Fragment, useState } from 'react'
import { Api } from 'util/fetcher'

const NewsletterForm = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const onSubscribe = async (values) => {
    try {
      await Api().post('/subscribe', values)
      setIsSuccess(true)
    } catch (e) {}
  }

  return (
    <div className="bg-white rounded shadow-md p-4 md:p-8">
      <h6 className="md:text-xl font-semibold mb-4">Senarai Surat</h6>

      {isSuccess ? (
        <div className="flex">
          <div className="mr-3">
            <img src={require('images/success-icon.svg')} alt="success" />
          </div>
          <div className="flex-1">
            <h2 className="text-xl font-semibold mt-1 mb-2">Terima kasih</h2>
            <div className="text-gray-700">
              <p className="mb-2">
                Posel yang Anda masukkan telah berhasil didaftarkan.
              </p>
              <p>Kami akan segera berkabar ke posel Anda.</p>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <Formik initialValues={{ email: '' }} onSubmit={onSubscribe}>
            <Form className="relative">
              <Input
                type="email"
                placeholder="Masukan surel anda"
                className="w-full pr-12 py-3"
                name="email"
              />
              <Submit
                color=""
                className="absolute right-0 top-0 bg-transparent py-3"
              >
                <FontAwesomeIcon icon={faArrowRight} className="h-6 w-6" />
              </Submit>
            </Form>
          </Formik>
          <p className="text-gray-700 mt-4 text-sm md:text-base">
            Daftarkan surel Anda di mailing list newsletter <br />
            Direktorat SMK dengan mengisi formulir ini.
          </p>
        </Fragment>
      )}
    </div>
  )
}

export default NewsletterForm
